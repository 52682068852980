const inlineScriptTag = document
	.createElement('script')
inlineScriptTag.setAttribute('type', 'text/javascript')
inlineScriptTag.setAttribute('id', 'gaInline');
inlineScriptTag.innerHTML =
	'(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start": new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f);})(window,document,"script","dataLayer","GTM-MVX9QG");';
const noScriptTag = document.createElement('noscript');
noScriptTag.innerHTML =
	'<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MVX9QG" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>';

const appendScripts = () => {
	document.body.appendChild(inlineScriptTag);
	document.head.appendChild(noScriptTag);
};

!(function(props) {
	var c = document.createElement('script');
	(c.src = 'https://privacy.reeltime.no/privacy-1.0.3.min.js'),
		(c.type = 'text/javascript'),
		(c.async = 1),
		document.head.appendChild(c),
		(c.onload = function() {
			Privacy.config(props);
		});
})({
	css_paths: ['https://privacy.reeltime.no/assets/privatmegleren/privatmegleren-theme.css'],
	texts: {
		title: 'Min Side',
		subtitle: 'Senter for personvern'
	},
	setup: function() {
		const cookie = this.cookie('_privacy');
		if (cookie) {
			const { performance, marketing } = cookie;
			window.allowPerformance = performance;
			window.allowMarketing = marketing;
			appendScripts();
		}
	},
	event: function(event) {
		if (event.consent.id === 'performance') {
			window.allowPerformance = event.consent.status;
		} else if (event.consent.id === 'marketing') {
			window.allowMarketing = event.consent.status;
		}
	},
	close: function() {
        const cookie = this.cookie('_privacy');
		if (cookie) {
			const { performance, marketing } = cookie;
			window.allowPerformance = performance;
			window.allowMarketing = marketing;
		} else {
			window.allowPerformance = false;
			window.allowMarketing = false;
		}
		if (!document.getElementById('gaInline')) {
			appendScripts();
		}
	},
	templates: {
		privacy: {
			disclaimer: {
				title: 'Ditt personvern',
				template: 'https://privacy.reeltime.no/assets/privatmegleren/disclaimer.html'
			}
		},
		cookies: {
			title: 'Cookies',
			necessary: {
				title: 'Helt nødvendige',
				cookies: [
					{
						name: 'font_domainedisplay_loaded',
						description: 'Sjekker om fonten Domaine Display er mellomlagret i nettleseren'
					},
					{
						name: 'font_proximanova_loaded',
						description: 'Sjekker om fonten Proxima Nova er mellomlagret i nettleseren'
					}
				] //{name, description}
			},
			performance: {
				title: 'Ytelse',
				question: {
					//title: 'Hjelp oss med å forbedre nettsiden',
					question_text:
						'Ønsker du at vi tar vare på anonym statistikk for bruken av nettsiden, slik at vi kan gjøre nettsiden bedre for deg?'
				},
				cookies: [
					{
						name: '_ga',
						description: 'Google. Used to distinguish users. Expiration time: 2 years'
					},
					{
						name: '_gid',
						description: 'Google. Used to distinguish users. Expiration time: 24 hours'
					},
					{
						name: '_gat',
						description: 'Google. Used to throttle request rate. Expiration time: 1 minute'
					}
				],
				links: [
					{
						title: 'Google Privacy Policy',
						href: 'https://support.google.com/analytics/answer/6004245',
						target: '_blank'
					},
					{
						title: 'Google Tag Manager',
						href: 'https://support.google.com/tagmanager/answer/6107124?hl=en',
						target: '_blank'
					}
				]
			},
			marketing: {
				title: 'Markedsføring',
				question: {
					//title: 'Annonsering der du er',
					question_text: 'Ønsker du at vi anbefaler annonser og innhold for deg basert på anonym bruk av denne nettsiden?'
				},
				cookies: [
					{
						name: 'Adform',
						description: 'Konverteringssporing',
						href: 'https://site.adform.com/privacy-policy-opt-out/',
						target: '_blank'
					},
					{
						name: 'DoubleClick',
						description: 'Konverteringssporing',
						href: 'https://support.google.com/ads/answer/2662922?hl=en',
						target: '_blank'
					},
					{
						name: 'Facebook',
						description: 'Konverteringssporing, optimalisering, remarketing',
						href: 'https://www.facebook.com/help/568137493302217',
						target: '_blank'
					},
					{
						name: 'Google',
						description: 'Konverteringssporing',
						href: 'https://support.google.com/ads/answer/2662922?hl=no',
						target: '_blank'
					},
					{
						name: 'Optimizely',
						description: 'Website optimisation platform',
						href: 'https://www.optimizely.com/',
						target: '_blank'
					},
					{
						name: 'UTM',
						description:
							'Urchin Tracking Module (UTM) parameters are five variants of URL parameters used by marketers to track the effectiveness of online marketing campaigns across traffic sources and publishing media.',
						href: 'https://en.wikipedia.org/wiki/UTM_parameters',
						target: '_blank'
					},
					{
						name: 'YourAdChoices',
						description: 'Konverteringssporing',
						href: 'http://optout.aboutads.info',
						target: '_blank'
					}
				],
				links: [
					{
						title: 'Facebook (GDPR)',
						href: 'https://www.facebook.com/business/gdpr',
						target: '_blank'
					}
				]
			}
		},
		transparency: {
			enabled: false,
			title: 'Innsyn',
			endpoint: 'https://privacy.reeltime.no/proxy/privatmegleren.php',
			schema: {
				request_pin: {
					placeholder: 'E-post / telefonnummer',
					info_text: 'Fyll inn ditt mobiltelefonnummer eller e-post for å bestille PIN-kode',
					button_text: 'Bestill PIN-kode'
				},
				verify_pin: {
					placeholder: 'PIN-kode',
					info_text: 'Fyll inn PIN-kode og klikk deretter på knappen',
					button_text: 'Logg inn'
				},
				report: {
					placeholder: 'Din e-post',
					info_text: 'Fyll inn e-post og få tilsendt rapport',
					button_text: 'Bestill'
				}
			},
			consents: {
				title: 'Samtykker og sletting'
			}
		}
	}
});
